import * as React from "react";
import PageHeader from "../core/pageHeader";
import BaseLayout from "./baseLayout";

interface TitledLayoutProps {
    title: string;
}

const TitledLayout: React.FunctionComponent<TitledLayoutProps> = ({
    title,
    children
}) => (
    <BaseLayout>
        <PageHeader title={title} />

        {children}
    </BaseLayout>
);

export default TitledLayout;
