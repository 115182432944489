import * as React from "react";

interface PageHeaderProps {
    title: string;
}

const PageHeader: React.FunctionComponent<PageHeaderProps> = ({ title }) => (
    <div className="py-32 bg-gray-700 text-gray-100">
        <div className="container mx-auto">
            <h1 className="text-5xl mb-0">{title}</h1>
        </div>
    </div>
);

export default PageHeader;
