import * as React from "react";
import BaseLayout from "../../components/layouts/baseLayout";
import TitledLayout from "../../components/layouts/titledLayout";

const StoreHome: React.FunctionComponent = () => (
    <TitledLayout title="Artwork By Rose">
        <div className="container mx-auto py-4 space-y-8">
            <div>
                <h2>Currently For Sale</h2>
                <p>TODO</p>
            </div>

            <div>
                <h2>Recently Sold</h2>
                <p>TODO</p>
            </div>
        </div>
    </TitledLayout>
);

export default StoreHome;
